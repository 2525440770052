import React from 'react';

import BlogPostPreview from 'components/BlogPostPreview';
import { Section } from 'components/Section';

import styles from './style.module.scss';

export default function BlogPostPreviews({title, posts}){
    let previews = posts.map(post => {
        let meta = post.node.frontmatter;

        if (!meta.title || 
            !meta.featuredImg ||
            !meta.previewText ||
            !meta.revisions ||

            !meta.author ||
            !meta.author.name ||
            !meta.author.profilePic){
            throw new Error('Missing post meta data');
        }

        return <BlogPostPreview key={post.node.fields.slug} 
                title={meta.title}
                previewImg={meta.featuredImg.childImageSharp.fluid}
                previewImgAlt={meta.featuredImgAlt}
                previewText={meta.previewText}
                slug={post.node.fields.slug}
                authorName={meta.author.name}
                authorImg={meta.author.profilePic.childImageSharp.fixed}
                revisions={meta.revisions}
                />;
    });

    if (!previews){
        return;
    }

    return (
        <Section className={styles.cont}>
            <h2>{title}</h2>
            <div className={styles.BlogPostPreviews}>
                {previews}
            </div>
            <div className={styles.previewsFade} />
        </Section>
    );
}