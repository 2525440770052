import React from 'react';
import Img from "gatsby-image";
import PropTypes from "prop-types";

import IndyLink from 'components/IndyLink';

import styles from './style.module.scss';
import RevTime from 'components/RevTime';

class BlogPostPreview extends React.Component {
    constructor(props) {
        super(props);
    }

    render(){
        // Computer whether a banner is required
        let latestType = null;
        let latestTS = null;
        for (let i = 0; i < this.props.revisions.length; i++) {
            const TS = this.props.revisions[i].time;
            if (latestTS === null || latestTS < TS){
                latestTS = TS;
                if (latestType === null){
                    latestType = "New";
                }else{
                    latestType = "Updated";
                }
            }
        }
        if (((Date.now() / 1000) - (60*60*24*45)) > latestTS){
            latestType = null;
        }

        return (
            <IndyLink adr={"/blog/" + this.props.slug} className={styles.container}>
                <article className={styles.article}>
                    <div className={styles.titleCont}>
                        <Img className={styles.prevImg}
                            fluid={this.props.previewImg}
                            alt={this.props.previewImgAlt}
                        />
                        <div className={styles.authorImgWrap}>
                            <Img className={styles.authorImg}
                                fixed={this.props.authorImg}
                                alt={this.props.authorName}
                            />
                        </div>
                        <div className={styles.titleWrap}>
                            <h3>{this.props.title}</h3>
                        </div>
                        <div className={styles.detailsText}>
                            <RevTime revisions={this.props.revisions} />
                            <p>{this.props.authorName}</p>
                        </div>
                    </div>
                    <div className={styles.previewText}>
                        <p>
                            {this.props.previewText}
                        </p>
                    </div>
                    {latestType && 
                        <div className={styles.bannerTag}>{latestType}</div>
                    }
                </article>
            </IndyLink>
        );
    }
}

BlogPostPreview.propTypes = {
    title: PropTypes.string.isRequired,
    previewImg: PropTypes.object.isRequired,
    previewImgAlt: PropTypes.string.isRequired,
    previewText: PropTypes.string.isRequired,
    slug: PropTypes.string.isRequired,
    authorName: PropTypes.string.isRequired,
    authorImg: PropTypes.object.isRequired,
    revisions: PropTypes.arrayOf(
        PropTypes.shape({
            time: PropTypes.number.isRequired
        })
    ).isRequired
};

export default BlogPostPreview;