import React from 'react';
import { graphql, useStaticQuery } from "gatsby";

import PageWrap from 'components/PageWrap';
import { Section } from 'components/Section';

import './blog.scss';

import { BorderedBox } from 'components/BorderedBox';
import BlogPostPreviews from 'components/BlogPostPreviews';
import { SEO } from 'components/Helmets';

import PagesBackgroundImg from 'media/pages/blog/Pages.jpg';

export default function BlogPage(){
    const pagePath = "/blog";

    let data = getData();

    const previewImg = {
        url: data.file.childImageSharp.fixed.src,
        height: data.file.childImageSharp.fixed.height,
        width: data.file.childImageSharp.fixed.width,
        alt: "Many pages of paper"
    }

    return(
        <PageWrap noSEO={true} path={pagePath}>
            <SEO
                title="Blog - Mashoom"
                description="A collection of posts across a wide range of topics, including data management and coding practices"
                previewImg={previewImg}
                path={pagePath}
            />
            <Section id="BlogHeaderWrap" style={{backgroundImage: "url(" + PagesBackgroundImg + ")"}}>
                <BorderedBox>
                    <div id="BlogHeader">
                        <h1>Our Blog</h1>
                    </div>
                </BorderedBox>
            </Section>
            <BlogPostPreviews title="Mashoom Updates" posts={data.mashoomPosts.edges} />
            <BlogPostPreviews title="Privacy, coding and all things web development" posts={data.JRPosts.edges} />
            <BlogPostPreviews title="Awesome, interesting and amazing; Volve" posts={data.VolvePosts.edges} />
            <BlogPostPreviews title="Racing and other insights from Will Dendy" posts={data.WDPosts.edges} />
        </PageWrap>
    );
}

function getData() {
    return useStaticQuery(graphql`
        query BlogContent {
            mashoomPosts: allMarkdownRemark(
                filter: {
                    fields: {
                        mdType: {
                            eq: "post"
                        }
                    }
                    frontmatter: {
                        tags: {
                            in: "Mashoom related"
                        }
                    }
                },
                sort: {
                    order: DESC, 
                    fields: frontmatter___revisions___time
                }, 
                limit: 9
            ) {
                ...postInfo
            },

            JRPosts: allMarkdownRemark(
                filter: {
                    fields: {
                        mdType: {
                            eq: "post"
                        }
                    }
                    frontmatter: {
                        author: {
                            id: {
                                eq: "JR"
                            }
                        }
                        tags: {
                            nin: "Mashoom related"
                        }
                    }
                },
                sort: {
                    order: DESC, 
                    fields: frontmatter___revisions___time
                }, 
                limit: 9
            ) {
                ...postInfo
            },

            VolvePosts: allMarkdownRemark(
                filter: {
                    fields: {
                        mdType: {
                            eq: "post"
                        }
                    }
                    frontmatter: {
                        tags: {
                            in: "Volve"
                        }
                    }
                },
                sort: {
                    order: DESC, 
                    fields: frontmatter___revisions___time
                }, 
                limit: 9
            ) {
                ...postInfo
            },

            WDPosts: allMarkdownRemark(
                filter: {
                    fields: {
                        mdType: {
                            eq: "post"
                        }
                    }
                    frontmatter: {
                        author: {
                            id: {
                                eq: "WD"
                            }
                        }
                    }
                },
                sort: {
                    order: DESC, 
                    fields: frontmatter___revisions___time
                }, 
                limit: 9
            ) {
                ...postInfo
            },

            file(sourceInstanceName: {eq: "media"}, relativePath: {eq: "pages/blog/Pages.jpg"}) {
                childImageSharp {
                    fixed(width: 800, height: 400, fit: COVER, cropFocus: CENTER) {
                        src
                        height
                        width
                    }
                }
            }
        }

        fragment postInfo on MarkdownRemarkConnection {
            edges {
                node {
                    fields {
                        slug
                    }
                    frontmatter {
                        previewText
                        title
                        author {
                            name
                            profilePic {
                                childImageSharp {
                                    fixed(width: 60, height: 60, fit: COVER, cropFocus: CENTER) {
                                        ...GatsbyImageSharpFixed_withWebp
                                    }
                                }
                            }
                        }
                        featuredImgAlt
                        featuredImg {
                            childImageSharp {
                                fluid(maxWidth: 400, maxHeight: 260) {
                                    ...GatsbyImageSharpFluid_withWebp
                                }
                            }
                        }
                        revisions {
                            time
                        }
                    }
                }
            }
        }
        `);
}